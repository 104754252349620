//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IndentedContent from '../Partials/IndentedContent';
import AccordionConstructor from '../Partials/AccordionConstructor';
export default {
    name: 'LinklistTeaser',
    components: {
        IndentedContent,
        AccordionConstructor,
    },
    props: ['data'],
    computed: {
        items() {
            const items = [1, 2, 3].map((i) => {
                return {
                    headline: this.data?.[`s${i}_headline`],
                    links: this.data?.[`s${i}_items`]?.map((item) => item?.[`s${i}_href`]).filter((o) => !!o),
                };
            });
            const itemsFiltered = items.filter((item) => item.headline || item.links?.length);
            return itemsFiltered;
        },
    },
};
